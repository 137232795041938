import { Component, OnInit, Input } from '@angular/core';
import {
  MatDialog,
} from '@angular/material/dialog';
import {
  GetLinkData,
  Planes,
  PlanesIncrease,
  StatusIncrease,
} from '../../../api/planes';
import { Perfil } from '../../../api/user';
import { ContratarPlan } from '../../../api/mercado';
import { PlanService } from '../../../services/plan/plan.service';
import { TiempoSubscripcionService } from '../../../services/tiempoSubscripcion/tiempo-subscripcion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CancelarComponent } from './cancelar/cancelar/cancelar.component';
import { ApiService } from '../../../api/api.service';
import { UpdateRangesService } from '../../../services/agenda/update-ranges.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PopupFacturacionComponent } from './popup-facturacion/popup-facturacion.component';
import { PlanMultipagoService } from '../../../services/plan/plan-multipago.service';
import { MultipagoService } from '../../admin/multipago/modules/multipago.service';
import { PlanMoneda, PlanPlataforma, Suscripcion } from '../../admin/multipago/modules/multipago.model';
import { Observable, Subject } from 'rxjs';
import { PopupPaypalPagoComponent } from '../../commons/dialogo/mercadopago/popup-paypal-pago/popup-paypal-pago.component';
import { PopupMercadopagoOpcionesComponent } from './popup-mercadopago-opciones/popup-mercadopago-opciones.component';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css'],
})
export class PlanComponent implements OnInit {
  planes: PlanesIncrease["planes"];
  plan: any;
  planContratado: StatusIncrease["suscripcion"];
  suscripcionSel: Suscripcion;
  posicion: number;
  contratarPlan: ContratarPlan;
  textoSubscripcion: string;
  @Input('abogado') perfil: Perfil;

  cachePlanes: Planes[] = [];
  cachePlanMonedas: PlanMoneda[] = [];
  cachePlanById = {};

  constructor(private router: Router,
    private planService: PlanService,
    private apiService: ApiService,
    private servicePlanMP: PlanMultipagoService,
    private serviceMultipago: MultipagoService,
    public dialog: MatDialog,
    public tiempoSubscripcionService: TiempoSubscripcionService,
    private snackBar: MatSnackBar,
    private updateRangesService: UpdateRangesService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT', 'en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    this.planService.verificoDatosFacturacion();
    this.getCuenta()
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.perfil = perfil;
        this.getPlanMonedaPlanesDips();
      },
      (error) => {
        console.error(error);
        this.snackBar.open(
          'No fue posible obtener la lista de planes, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    );

    /*this.planService.getPlanesAbogado().subscribe(
      (respuesta) => {
        this.planes = respuesta;
        this.planes.splice(this.planes.findIndex( e => e.nombre == "Plan Dummy"),1)
        this.getCuenta();
        this.plan = this.planes[0];
        this.posicion = 0;
        console.log('BASE DE DATOS:' + this.planes[2].jurisdicciones);
      },
      (error) => {
        console.error(error);
        this.snackBar.open(
          'No fue posible obtener la lista de planes, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    );*/

    
    this.getPlanes()
  }

  getPlanMonedaPlanesDips(): void {
    this.servicePlanMP.getPlanMonedaPlanesDips().subscribe({
      next: (planes: Planes[]) => {
        this.serviceMultipago.getPlanMonedasByCountryUser().subscribe({
          next: (planMonedas: PlanMoneda[]) => {
            let planesFilter: Planes[] = [];
            let mapPlanMoneda = {};
            let pm: PlanMoneda;
            this.cachePlanMonedas = planMonedas;

            this.cachePlanById = {};
            planMonedas.forEach((planMoneda) => {
              mapPlanMoneda[planMoneda.planMonedaId] = planMoneda;
              if (!this.cachePlanById[planMoneda.planId] || planMoneda.principal) {
                this.cachePlanById[planMoneda.planId] = planMoneda;
              }
            });

            Object.keys(this.cachePlanById).forEach((planId) => {
              pm = this.cachePlanById[planId];
              planes.some((plan) => {
                if (plan.planTipoId == 2 && plan.id_plan == pm.planId && plan.costo == pm.monto && plan.costoAnual == pm.montoAnual) {
                  plan.nombrePlan = plan.nombre;
                  plan.planPlataformasMes = [];
                  plan.planPlataformasAnio = [];
                  planesFilter.push(plan);
                }
              });
            });

            this.serviceMultipago.getPlanPlataformas().subscribe({
              next: (planPlataformas: PlanPlataforma[]) => {
                planPlataformas.forEach((planPlataforma) => {
                  if (this.perfil.pais != "" + planPlataforma.countryId)
                    return;
                  if (planPlataforma.planTipoId != 2)
                    return;
                  if (!planPlataforma.activo)
                    return;
                  planesFilter.some((plan: Planes) => {
                    if (planPlataforma.planId == plan.id_plan) {
                      let planMoneda: PlanMoneda = mapPlanMoneda[planPlataforma.planMonedaId];
                      if (planPlataforma.plataformaMonedaEstado && planMoneda.visible) {
                        switch (planPlataforma.ciclo) {
                          case 1:
                            if (planMoneda && planMoneda.principal) {
                              plan.planPlataformasMes.unshift(planPlataforma);
                              plan.moneda = planMoneda.monedaId;
                              plan.costo = planPlataforma.monto;
                            } else {
                              plan.planPlataformasMes.push(planPlataforma);
                              if (!plan.moneda) {
                                plan.moneda = planMoneda.monedaId;
                                plan.costo = planPlataforma.monto;
                              }
                            }
                            break;
                          case 2:
                            if (planMoneda && planMoneda.principal) {
                              plan.planPlataformasAnio.unshift(planPlataforma);
                              plan.monedaAnual = planMoneda.monedaId;
                              plan.costoAnual = planPlataforma.monto;
                            }
                            else {
                              plan.planPlataformasAnio.push(planPlataforma);
                              if (!plan.monedaAnual) {
                                plan.monedaAnual = planMoneda.monedaId;
                                plan.costoAnual = planPlataforma.monto;
                              }
                            }
                            break;
                        }
                      }
                      return true;
                    }
                    return false;
                  });
                });

                this.cachePlanes = planesFilter;
              }
            });
          }
        });
      }
    });
  }

  getCuenta() {
    this.planService.getClientStatus().subscribe(res => {
      if (res.mensaje == "OK") {
        if(res.suscripcion) {
          this.planContratado = res.suscripcion;
          this.textoSubscripcion = this.tiempoSubscripcionService.getTiempo(res.suscripcion);
          if(res.cuit == null || res.cuit.length < 1 || res.codigoPostal == null || res.direccion == null){
            const dialogRef = this.dialog.open(PopupFacturacionComponent, {
              backdropClass: 'backdropBackground',
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (!result) {
                this.planService.verificoDatosFacturacion();
                window.location.reload();
              }
            });
          }
        }
      }
    }, err => {
      console.log(err)
    });

    this.servicePlanMP.getSubscripcionLast().subscribe({
      next: (suscripcion: Suscripcion) => {
        this.suscripcionSel = suscripcion;
      }
    });
  }

  getPlanes() {
    this.planService.getPlanesIncrease().subscribe((res) =>{
      this.planes = res.planes.map(e => {
        switch (e.nombrePlan) {
          case "Plan Basico":
            e.especialidades = 1;
            e.jurisdicciones = 1;
            break;
          case "Plan Premium":
            e.especialidades = 3;
            e.jurisdicciones = 2;
            break;
          case "Plan Corporativo":
            e.especialidades = 9999;
            e.jurisdicciones = 9999;
            break;
        }
        e.planVencido = false
        return e
      })
      //Delete PLan Basico of array this.planes twice
      this.planes.splice(this.planes.findIndex( e => e.nombrePlan == "Plan Basico"),1)
      this.planes.splice(this.planes.findIndex( e => e.nombrePlan == "Plan Basico"),1)
      this.planes.sort((a, b) =>  Number(a.precio) - Number(b.precio));
      }, (error) => {
        throw error
      })
    this.posicion = 0
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  contratar(planCont) {
    window.open(planCont.checkoutLink, "_self")
  }

  elegirPlanPlataforma(planPlataforma: PlanPlataforma): void {
    const subject: Subject<PlanPlataforma> = new Subject();

    subject.subscribe({
      next: (result: PlanPlataforma) => {
        this.servicePlanMP.doAbogadoSuscribir(result).subscribe({
          next: (checkoutUrl: string[]) => {
            if (checkoutUrl)
              window.open(checkoutUrl[0], "_self")
          },
          error: (err: any) => {
            if (err.error && err.error.message) {
              this.snackBar.open(
                err.error.message,
                '',
                { duration: 5000 }
              );
            }
            console.log(err);
          }
        });
      }
    });
    
    if (planPlataforma.plataformaNombre == "MercadoPago") {
      let dialogRef = this.dialog.open(PopupMercadopagoOpcionesComponent, {
        data: {
          planPlataforma: planPlataforma
        }
      });

      dialogRef.componentInstance.onReturnURL.subscribe({
        next: (result: PlanPlataforma) => {
          subject.next(result);
        }
      });
      // dialogRef.componentInstance.onSelPlataforma();
    } else if (planPlataforma.plataformaNombre == "Paypal") {
      const dialogRef = this.dialog.open(PopupPaypalPagoComponent, {
        backdropClass: 'backdropBackground',
        data: {
          planUuid: planPlataforma.uuid,
          usuarioId: this.perfil.id_usuario
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response)
          return;
        
        this.router.navigate(['/plan/exito'], {
          queryParams: {
            type: "paypal",
            uuid: response.subscriptionID
          }
        });
      });
    } else {
      subject.next(planPlataforma);
    }    
  }

  isPlanWithSuscription(plan: Planes, planPlataformas: PlanPlataforma[], suscripcion: Suscripcion): boolean {
    let result: boolean = false;

    if (plan.id_plan != suscripcion.planId)
      return false;
    result = planPlataformas.some((planPlataforma) => {
      if (suscripcion.planId == planPlataforma.planId)
        return true;
      return false;
    });
    
    return result;
  }

  isSuscripcionActivo(plan: Planes, suscripcion: Suscripcion): boolean {
    let activo: boolean = suscripcion.estado == 'active';

    activo = plan.id_plan == suscripcion.planId;
    activo = activo && suscripcion.estado == 'active';

    return activo;
  }

  isSuscripcionActivoWithRaw(): boolean {
    let activo: boolean;

    activo = this.suscripcionSel && this.suscripcionSel.estado == 'active' && this.suscripcionSel.estadoRaw != 'pending_canceled';
    return activo;
  }

  isSuscripcionInactivo(plan: Planes, suscripcion: Suscripcion): boolean {
    let activo: boolean;

    activo = plan.id_plan == suscripcion.planId;
    activo = activo && suscripcion.estado == 'canceled' || suscripcion.estado ==  'pending';

    return activo;
  }

  elegir(planElegido, anual: boolean) {
    let getLinkData: GetLinkData = {
      planId: planElegido.id_plan,
      anual: anual,
    };
    let eleccionObj = {
      planId: planElegido.planId
    }
    if(this.planContratado && this.planContratado.planId != planElegido.id_plan && this.planContratado.status !== "pending" && this.planContratado.status !== "canceled"){
      const dialogRef = this.dialog.open(CancelarComponent, {
        data: {
          titulo: 'Ya tienes un plan contratado',
          texto:
            'Tendras que cancelar el plan actual para cambiar a otro. Al hacerlo, no podrás utilizar ningún servicio hasta que vuelvas a contratar otro plan. No se realizará ningún reintegro de los días que restan para finalizar el ciclo de facturación.',
          textoBotonCerrar: 'NO',
          textoBotonConfirmar: 'CANCELAR PLAN',
          mostrarCancelar: true,
        },
        backdropClass: 'backdropBackground',
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.planService.deleteClientPlan({ idSuscripcion: this.planContratado.suscripcionId}).subscribe( res =>   {
            console.log(res)
          }, err => console.log(err))
          this.planService.setIncreaseClient(eleccionObj).subscribe(res => {
            console.log(res)
            if (res.mensaje == "OK") {
              window.open(res.chekoutLink, "_self")
            }
          }, err => {
            console.log(err)
          })
        }
      });
    }else{
      this.planService.setIncreaseClient(eleccionObj).subscribe(res => {
        console.log(res)
        if (res.mensaje == "OK") {
          window.open(res.chekoutLink, "_self")
        }
      }, err => {
        console.log(err)
      })
    }
  }


  cancelar() {
    const dialogRef = this.dialog.open(CancelarComponent, {
      data: {
        titulo: '¿Deseas cancelar tu plan?',
        texto:
          'Al hacerlo, no podrás utilizar ningún servicio hasta que vuelvas a contratar otro plan. No se realizará ningún reintegro de los días que restan para finalizar el ciclo de facturación.',
        textoBotonCerrar: 'NO',
        textoBotonConfirmar: 'CANCELAR PLAN',
        mostrarCancelar: true,
      },
      backdropClass: 'backdropBackground',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removePlangotPerfil();
      }
    });
  }

  removePlangotPerfil() {
    console.log(this.suscripcionSel);

    this.servicePlanMP.doAbogadoSuscripcionCancelar(this.suscripcionSel).subscribe({
      next: () => {
        this.snackBar.open('El plan se canceló correctamente', '', {
          duration: 5000,
        });
        this.getCuenta();
      },
      error: (err) => {
        if (err.error && err.error.message) {
          this.snackBar.open(
            err.error.message,
            '', {
              duration: 5000
            }
          );
        } else if (err.message) {
          this.snackBar.open(
            err.message,
            '', {
              duration: 5000
            }
          );
        }
        console.log(err);
      }
    });

    return;
    let planDeleteObj = {
      idSuscripcion: this.planContratado.suscripcionId
    }

    console.log(planDeleteObj)
    this.planService.deleteClientPlan(planDeleteObj).subscribe(
      (respuesta) => {
        this.snackBar.open('El plan se canceló correctamente', '', {
          duration: 5000,
        });
        this.router.navigate(['/perfil-abogado']);
      },
      (error) => {
        console.error(error);
        this.snackBar.open(
          'No fue posible cancelar tu plan, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    );
  }

  removePlanAsync() {

    this.planService.removePlan().subscribe(
      (respuesta) => {

      },
      (error) => {
        console.error(error);
        this.snackBar.open(
          'No fue posible cancelar tu plan, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    );

  }

  openDialogCurso() {
    const dialogRef = this.dialog.open(CancelarComponent, {
      data: {
        titulo: 'Tienes un plan en curso',
        texto:
          'Para suscribirte a otro plan, primero debes cancelar el plan que tienes en curso',
        textoBotonCerrar: 'ENTENDIDO',
        mostrarCancelar: false,
      },
      backdropClass: 'backdropBackground',
    });
  }
}
